@import 'https://js.arcgis.com/4.24/@arcgis/core/assets/esri/themes/light/main.css';
html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

body {
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}