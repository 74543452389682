.buttonToggle {
  z-index: 2;
  position: absolute;
  top: 50px;
  left: 3em;
  margin: 20px;
  border:1px;
  border-color: #000000;
}
.app_header{
  top: 5px;
  left: 55px;
  position: absolute;
  z-index: 2;
  background-color: white;
  border: solid black 1px;
  justify-content: center;
  align-items: center;
  display: flex;
  /* margin: auto; */
  height: 50px;
  width: 300px;
  border-radius: 8px;
}


.widget{
  z-index: 2;
  position: absolute;
  top: 370px;
  left: 15px;
  background-color: white;
  /* opacity: 0.8; */
  /* border-radius: 10px; */
  border: 1px;
  border-color: black;
  }
.padding {
    padding: 10px;
    padding-top: 20px;
}

.header a{
    font-size: 20px;
}
.header {
    background-color: #f2f3f4;
    border-bottom: 1px solid grey;
    text-align: center;
}
.dateFrom, .dateTo{
    margin: auto;
    text-align: center;
}
.container{
  align-items: center;
  display: flex;
    /* display:inline-block; */
}
.img{
  max-width: 50px;
  border-radius: 10px;
  max-height: 50px;
  touch-action: manipulation;
  transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
}
.img:hover,.arr_img:hover {
  opacity: 0.5;
  cursor: pointer;
}
.img:hover,.arr_img:active {
  transform: scale(.96);
}



.arr_img{
  max-width: 10px;
  max-height: 10px;
  justify-content: center;
  text-align: center;
}

.iconWidget{
  padding: 10px;
  z-index: 2;
  position: absolute;
  top: 150px;
  left: 15px;
  background-color: white;
  /* opacity: 0.8; */
  border-radius: 10px;
  border: 1px;
  border-color: black;
  /* justify-content: center; */
  display:flex;
}

.sideButton {
  /* width: 10px; */
  height: 100%;
  /* background-color: black; */
  padding: 20px; 
  display: inline-block;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  align-items: center;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/*This is data source buttons */
.dataWidget {
  z-index: 2;
  position: absolute;
  top: 250px;
  left: 15px;
  background-color: white;
  /* opacity: 0.8; */
  border-radius: 10px;
  border: 1px;
  border-color: black;
  /* justify-content: center; */
  display:absolute;
}


.sidebar {
  z-index: 2;
  height: 85%;
  width: 400px;
  position: absolute;
  display: absolute;
  right: 16px;
  top: 100px;
  padding-top: 5px;
  background-color: white;
}
.block{
  /* z-index: inherit; */
  width: 100%;
  height: auto;
  background-color: #F2F3F4;
  display: flex;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.diagram{
  display: block;
  text-align: center;
}








/* CSS for buttons*/
.buttonApp.active {
  box-shadow: #222222 0 0 0 2px;
  transition: box-shadow .2s;
  background-color: #D3D3D3;
}

.button-23 {
    margin-right: 3px;
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    /* font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif; */
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    outline: none;
    padding: 10px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
}

.button-23:focus {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
}

.button-23:active {
  background-color: #F7F7F7;
  border-color: #000000;
  transform: scale(.96);
}

.button-23:disabled {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
  opacity: 1;
}

.button-23.active{
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow .2s;
  background-color: #D3D3D3;
}

.notready3 {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
}

.notready3:hover {
  border-color: #DDDDDD;
  color: #DDDDDD;
  cursor: not-allowed;
}

.zoomAndQuery{
  padding: 5px;
  z-index: 2;
  position: absolute;
  top: 270px;
  left: 15px;
  background-color: white;
  /* opacity: 0.8; */
  border-radius: 5px;
  border: 1px;
  border-color: black;
  /* justify-content: center; */
  display:flex;
}
/*
#analyticsChartWeek{
  height: 250px !important;
  width: 250px !important;
}*/