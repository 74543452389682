/* skiltis didele
1680x907
*/

.Header {
  position: absolute;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  width: 14.3vw;
  line-height: 130%;
  padding-left: 1vw;
  padding-right: 1vw;
  height: 16vh;
  left: 0px;
  border-top-right-radius: 27px 27px;
  background: #bd1818;
  z-index: 2;
  color: #efefef;
  vertical-align: middle;
  font-weight: 600;
  font-size: 1.7vh;
}

.MapOption.Second {
  position: absolute;
  width: 16.3vw;
  height: 8.9vh;
  left: 0px;
  top: 23vh;
  background: #878787;
  z-index: 5;
}

.MapOption.Active {
  position: absolute;
  width: 16.3vw;
  height: 8.9vh;
  left: 0px;
  top: 28vh;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 6;
}

.MapOption.Third {
  position: absolute;
  width: 16.3vw;
  height: 8.9vh;
  left: 0px;
  top: 18vh;
  background: #A1A1A1;
  z-index: 4;
}

.MapOption.Fourth {
  position: absolute;
  width: 16.3vw;
  height: 8.9vh;
  left: 0px;
  top: 13vh;
  background: #D2D1D1;
  z-index: 3;
}

.MapOption {
  color: #efefef;
  font-weight: 600;
  font-size: 1.7vh;
  border-top-right-radius: 27px 27px;
  border-top-left-radius: 27px 27px;
  border: 0;
}

.MapOption:hover {
  cursor: pointer;
}

.IconWidget {
  font-size: 1.6vh;
  position: absolute;
  width: 16.3vw;
  height: 28vh;
  left: 0px;
  top: 33vh;
  overflow: auto;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 6;
}

.IconContainerALT{
  height: 7.922vh;
}

.IconContainer {
  font-size: 1.3vh;
  font-weight: 600;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  align-items: center;
  display: inline-block;
  color: #efefef;
  background: #85bc19;
  width: 4.4vw;
  height: 4.4vw;
  margin-top: 1.2vh;
  margin-bottom: 1.2vh;
  margin-left: 0.71vw;
  margin-right: 0.71vw;
  border-radius: 100%;
  box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.3);
  border: none;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
}

.IconContainer:hover {
  opacity: 0.96;
  cursor: pointer;
  transform: scale(0.96);
}

.IconContainer.Disabled:hover {
  opacity: 1;
  cursor: not-allowed;
  transform: none;
}

.IconContainer.Active {
  align-items: center;
  display: inline-block;
  color: #85bc19;

  background: #efefef;
  width: 4.4vw;
  height: 4.4vw;
  margin-top: 1.2vh;
  margin-bottom: 1.2vh;
  margin-left: 0.71vw;
  margin-right: 0.71vw;
  border-radius: 100%;
  box-shadow: inset 2px 2px 2px rgba(119, 119, 119, 0.664);
}

.IconContainer.Disabled {
  align-items: center;
  display: inline-block;
  color: #efefef;
  background: #878787;
  width: 4.4vw;
  height: 4.4vw;
  margin-top: 1.2vh;
  margin-bottom: 1.2vh;
  margin-left: 0.71vw;
  margin-right: 0.71vw;
  border-radius: 100%;
  box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.3);
}

.DataWidget {
  font-size: 1.6vh;
  position: absolute;
  width: 16.3vw;
  height: 14.7vh;
  left: 0px;
  top: 59vh;
  overflow: auto;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 7;
  padding-top: 5px;
}

.DateWidget {
  font-size: 1.6vh;
  position: absolute;
  width: 16.3vw;
  height: 24.5vh;
  overflow: auto;
  left: 0px;
  top: 72.8vh;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 4;
  padding-top: 20px;
  border-bottom-right-radius: 27px 27px;
  z-index: 8;
}

.Footer {
  position: absolute;
  width: 16.3vw;
  height: 4.4vh;
  top: 57.8vh;
  left: 0px;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 6;
  padding-top: 20px;
  border-bottom-right-radius: 27px 27px;
}

.Sidebar {
  position: absolute;
  width: 22vw;
  height: 88.1vh;
  right: 0px;
  top: 2vh;
  background: #202020;
  color: #EFEFEF;
  box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  padding-top: 3.3vh;
  padding-bottom: 3.3vh;
  padding-left: 1.78vw;
  padding-right: 1.78vw;
  overflow: auto;
  overflow-x: hidden;
  z-index: 5;
}

#AnalyticsSidebar{
  border-top-left-radius: 0%;
  top: 6.5vh;
  height: 84vh;
}

.LegendContainer {
  position: absolute;
  width: 25vw;
  height: 33vh;
  right: 1vw;
  bottom: 2.5vh;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  z-index: 4;
  padding-top: 10px;
  border-radius: 27px;
}

.LegendContainerTable{
  width: 100%;
  height: 100%;
}
.LegendContainerTableRow{
  text-align: left;
  padding-left: 50px;
}


.LegendBtn {
  position: absolute;
  width: 4.5vw;
  height: 6vh;
  right: 1vw;
  bottom: 2.5vh;
  color: #efefef;
  font-weight: 600;
  text-align: center;
  background: #202020;
  box-shadow: 6px 6px 6px rgba(255, 255, 255, 0.3);
  z-index: 4;
}

.LegendContainer:hover {
  cursor: pointer;
  transform: scale(0.99);
}



.LegendBtn:hover {
  opacity: 0.96;
  cursor: pointer;
  transform: scale(0.96);
}

.LegendRanges {
  width: 40%;
  float: right;
  padding-right: 50px;
  padding-top: 0%;
  line-height: 302%;
  text-align: left;
}

.LegendCanvasClass{
  width: 30%;
  height: 3vh;
  float: left;
  padding-left: 35px;
  padding-right: 10%;
  padding-top: 0.6%;
  padding-bottom: 2%;
}

#LegendCanvasId {
  width: 100%;
  height: 100%;
  
}

#legendIMG {
  width: 4.5vw;
  height: 6vh;
}

.DatePickerWrapper{
    margin-top: 10px !important;
    border-top-left-radius: 27px !important;
    border-top-right-radius: 27px !important;
    color: #EFEFEF !important;
    background-color: #85bc19 !important;
}

.Inliner {
  border-radius: 27px !important;
}

.Green {
  color: #EFEFEF !important;
  background-color: #85bc19 !important;
}

.Blue{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  border-top-left-radius: 27px !important;
  border-top-right-radius: 27px !important;
  color: #202020 !important;
  background-color: #55ffff !important;
}

.DatePickerClose{
  position: relative;
  top: -8px !important;
  height: 28px !important;
  width: 243px !important;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.DatePickerClose:hover{
  transform: none !important;
}

.DatePickerOpen{
  position: relative;
  height: 25px !important;
  width: 12vw !important;

}

#LegendCanvasTitle {
  padding-top: 2%;
  width: 30%;
  float: left;
  padding-left: 40px;
  margin: 0px;
}

#LegendRangesTitle {
  width: 30%;
  float: left;

  padding-left: 9%;
  padding-top: 2%;
  text-align: left;
}

#dayButton {
  position: absolute;
  border-top-left-radius: 4.4vw !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 4.4vw !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 0;
  width: 2.2vw !important;
  left: 0 !important;
  margin-left: 0;
}

#hourButton {
  position: absolute;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 4.4vw !important;
  border-bottom-left-radius: 0  !important;
  border-bottom-right-radius: 4.4vw !important;
  width: 2.2vw !important; 
  margin-left: 0;
  right: 0 !important;
  margin-right: 0;
}

#circleContainer{
  position: relative;
  width: 4.4vw;
  height: 4.4vw;
}

#buttonContainer{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 0vw 0.6vw;
  width: 9.5vw;
  justify-items: center;
  padding-left: 1.5vw;
}

#OperatorNextToLargeText{
  margin-left: 1.2vw;
  top: 0!important;
}

.react-datepicker__header{
    
    color: #EFEFEF !important;
    background-color: #85bc19 !important;
}

.Blue .react-datepicker__header {
  color: #202020 !important;
  background-color: #55ffff !important;
  border-top-left-radius: 27px !important;
  border-top-right-radius: 27px !important;
  border: none;
}

.react-datepicker__header {
    color: #EFEFEF;
    border-top-left-radius: 27px !important;
    border-top-right-radius: 27px !important;
}

.Blue .react-datepicker__current-month {
  color: #202020 !important;
}

.react-datepicker__current-month {
    color: #EFEFEF !important;
}

.Blue .react-datepicker__day-name{
  color: #202020 !important;
  font-weight: 600 !important;
}


.react-datepicker__day-name{
    color: #EFEFEF !important;
    font-weight: 600 !important;
}

.Blue .react-datepicker__day--selecting-range-start{
  background-color: #202020 !important;
  color: #55ffff !important;
}

.react-datepicker__day--selecting-range-start{
  background-color: #efefef !important;
  color: #85bc19 !important;
}

.Blue .react-datepicker__day--in-selecting-range {
  background-color: #2020208f !important;
  color: #55ffff !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: #efefef8f !important;
  color: #85bc19 !important;
}

.Blue  .react-datepicker__day{
  color: #202020 !important;
  font-weight: 600 !important;
}

.react-datepicker__day{
    color: #EFEFEF !important;
    font-weight: 600 !important;
}

.Blue .react-datepicker__day:hover{
  background-color: #2020208f !important;
  color: #55ffff !important;
}

.react-datepicker__day:hover{
    color: #85bc19 !important;
}

.Blue .react-datepicker__day--in-range {
  color: #55ffff!important;
  background-color: #202020 !important;
}

.react-datepicker__day--in-range {
    color: #85bc19 !important;
    background-color: #efefef !important;
}

.Blue .react-datepicker__day--disabled{
  /*font-size: 0 !important;*/
  color: #878787 !important;
  background-color: #55ffff!important;
  cursor: not-allowed !important;
}

.react-datepicker__day--disabled{
    /*font-size: 0 !important;*/
    color: #878787 !important;
    background-color: #85bc19 !important;
    cursor: not-allowed !important;
}

.Blue .react-datepicker__day--outside-month{
  color:  #55ffff!important;
}

.react-datepicker__day--outside-month{
  color:  #85bc19 !important;
}

.react-datepicker-ignore-onclickoutside {
    height: 20px;
    border-radius: 27px !important;

    font-weight: 600;
    text-align: center;
}

.react-datepicker__navigation--previous {
  left: 140px !important;
}

.react-datepicker__current-month{
  text-align: left !important;
  padding-left: 20px !important;
}

.Blue .DatePickerInput{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  line-height: 120%;
  font-size: 0.80vw;
  height: 25px !important;
  width: 12vw !important;
  border-radius: 27px !important;
  background-color: #55ffff;
  color: #202020 !important;
  font-weight: 600;
  text-align: center;
  margin: 5px;
}


.DatePickerInput{
    border: none;
    height: 25px !important;
    width: 12vw !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    line-height: 120%;
    font-size: 0.80vw;
    border-radius: 27px !important;
    background-color: #85bc19;
    color: #EFEFEF;
    font-weight: 600;
    text-align: center;
    margin: 5px;
    margin-left: 0px;
}

.DatePickerInput:hover{
    cursor: pointer;
    transform: scale(0.96);
}

.SidebarText{
    text-align: center;
}

.ButtonDisabled {
  position: absolute;
  width:  4.4vw;
  height:  4.4vw;
  left: 2.2vw;
  top: 60.1vh;

  background: rgba(135, 135, 135, 0.7);
}

.ClearBtn.Active{
    width: 10.7vw;
    height: 3.3vh;
    border-radius: 27px 27px;
    font-weight: 600;
    border: none;
    color:  #85bc19;
    background: #efefef;
    box-shadow: inset 2px 2px 2px rgba(119, 119, 119, 0.664);
}

.ClearBtn{
    font-family: 'Poppins' !important;
    font-style: normal !important;
    line-height: 120%;
    font-size: 0.80vw;
    width: 10.7vw;
    height: 3.3vh;
    border-radius: 27px 27px;
    font-weight: 600;
    border: none;
    color: #efefef;
    background: #85bc19;
}

.NonActive{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  line-height: 120%;
  font-size: 0.80vw;
  width: 10.7vw;
  height: 3.3vh;
  border-radius: 27px 27px;
  font-weight: 600;
  border: none;
  color: #efefef;
  background: #85bc19;
}

.NonActive:hover {
  opacity: 0.96;
  cursor: pointer;
  transform: scale(0.96);
}

#ChangeSize{
  right: 2vw;
  bottom: 4vh;
}

.SidebarBtn{
    width: 5.3vw !important;
    height: 2.7vh !important; 
    margin: 10px;
    margin-left: 0px;
    margin-bottom: 0px;
}

.ClearBtn:hover {
    opacity: 0.96;
    cursor: pointer;
    transform: scale(0.96);
}

.ClearBtn.Disabled{
    width: 10.7vw;
    height: 3.3vh;
    border-radius: 27px 27px;
    border: none;
    color: #efefef;
    background: #878787;
}

.ClearBtn.Disabled:hover {
    opacity: 1;
    cursor: auto;
    transform: none;
}

.ButtonInactive {
  /* Ellipse 1 */

  position: absolute;
  width:  4.4vw;
  height:  4.4vw;
  left: 2.2vw;
  top: 48.5vh;
  color: #efefef;
  background: #85bc19;
}

.ButtonActive {
  /* Ellipse 3 */

  position: absolute;
  width:  4.4vw;
  height:  4.4vw;
  left: 2.2vw;
  top: 77.7vh;
  color: #85bc19;
  background: #efefef;
}

#BikeIcon {
  
  position: relative;
  top: 1.1vh;
}

#BikeIconImg {
  position: relative;
  width: 90%; 
  max-width: 60px; 
  height: auto; 
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  top: 1vw;
}

#CarIcon {
  position: relative;
  top: -1.7vh;
}

#CarIconImg {
  position: relative;
  width: 90%; 
  max-width: 60px; 
  height: auto; 
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  top: 1vw;
}

#BusIcon {
  position: relative;
  top: 0.15vh;
}

#BusIconImg {
  position: relative;
  width: 90%; 
  max-width: 60px; 
  height: auto; 
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  top: 1vw;
}

#HumanIcon {
  position: relative;
  top: 0.9vh;
}


#HumanIconImg {
  position: relative;
  width: 60%; 
  max-width: 45px; 
  height: auto; 
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  top: 0.65vw;
}

#AllIcon {
  position: relative;
  left: -6.4vh;
  top: -0.9vh;
}

#AllIconImg {
  position: relative;
  width: 60%; 
  max-width: 45px; 
  height: auto; 
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 2%;
  top: 0.65vw;
}

#OperatorNextToLargeText {
  position: relative;
  top: -7px;
  vertical-align: top;
}

#analyticsChart {
    background: #202020;
}

#analyticsChartWeek{
    background: #202020;
}

#analyticsChartMonth{
    background: #202020;
}

.Date {
  position: relative;
  top: -20px;
  margin-bottom: 0px !important;
  font-size: 1.8vh;
}

.MapOptionText {
  position: relative;
  top: -12px;
  font-family: 'Poppins';
}

.MapOptionMid {
  position: absolute;
  width: 275px;
  height: 81px;
  left: 0px;
  top: 199px;
  background: #a2a1a1;
}

.MapOptionFirst {
  position: absolute;
  width: 275px;
  height: 81px;
  left: 0px;
  top: 252px;
  background: #878787;
}

#HideSidebarBtn {
  right: 25.5vw;
  position: absolute;
  height: 18.08vh;
  width: 2.38vw;
  top: 44.1vh;
  background: #202020;
  box-shadow: -4px 3px 2px rgba(255, 255, 255, 0.3);
  border-top-left-radius: 27px 27px;
  border-bottom-left-radius: 27px 27px;
  writing-mode: vertical-rl;
  visibility: visible;
  z-index: 6;
  color: #EFEFEF;
}



#VerticalTxt {
  transform: rotate(180deg);
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.SidebarHidden {
  visibility: hidden;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.loader {
  margin-left: 45%;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #85bc19; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.loaderCentered {
  margin-top: 40%;
}

#layerLoader{
  font-size: 1rem;
  position: inherit;
  margin: 0px;
  margin-left: 1.7vw;
  margin-top: 1.60vh;
  border: 5vh solid #f3f3f3; /* Light grey */
  border-top: 5vh solid #85bc19; /* Blue */
  width: 11vh;
  height: 11vh;
  min-width: 60px;
  min-height: 60px;
  z-index: 100;
}

#layerLoaderWrapper{
  text-align: center;
  top: 35%;
  right: 45%;
  position: absolute;
  width: 27.4vh;
  height: 27.4vh;
  min-width: 200px;
  min-height: 200px;
  color: #efefef;
  background: #202020ce;
  box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  z-index: 99;
}

.GreyCardWrapper{
  right: 2.05vw;
  position: fixed;
  height: 3.3vh;
  width: 23.5vw;
  top: 1.4vh;
  z-index: 3;
}

.GreyCard{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  line-height: 120%;
  font-size: 0.75vw;
  width: 3.8vw;
  height: 4vh;
  border-radius: 11px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-weight: 600;
  color: #efefef;
  border: none;
  background: #A1A1A1;
  margin-right: 1px !important;
  margin: 10px;
  margin-left: 0px;
  z-index: 3;
  
}

#GreyCardContext{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  width: 10.5vw;
  height: 4vh;
  border-radius: 11px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-weight: 600;
  
  border: none;
  
  margin-right: 1px !important;
  margin: 10px;
  margin-left: 0px;
  z-index: 3;
  
}


.GreyCard.Active{
  
  color: #efefef;
  background: #202020;

}

.GreyCard:hover {
  opacity: 0.96;
  background: #878787;
  cursor: pointer;
}

.GreyCard.Active:hover {
  opacity: 0.96;
  background: #202020;
  cursor: pointer;
}

.LeftmostCard{
  box-shadow: -10px 0px 4px -5px rgba(255, 255, 255, 0.3);
}

.LengthBtn{
  margin-top: 5px;
  margin-right: 5px;
  width: 5.3vw;
  height: 2.7vh; 
}

.LengthBtn.Active{
  margin-top: 5px;
  margin-right: 5px;
  width: 5.3vw;
  height: 2.7vh; 
}

.MarginBtn {
  margin: 10px;
  margin-left: 0px;
}

#exportBtn{
  margin-top:5px;
}

.ButtonColor{
  color: #efefef;
  background: #85bc19;
  border-radius: 100%;
  box-shadow: 2px 2px 2px rgba(255, 255, 255, 0.3);
}

.ButtonColor.Active{
  background:#efefef;
  color: #85bc19;
  border-radius: 100%;
  box-shadow: inset 2px 2px 2px rgba(119, 119, 119, 0.664);
}

.MenuColor{
  background: #202020;
  color: #EFEFEF;
}

.GridSelection{
  width: 4.4vw;
  height: 4.4vw;
  
}

.GridIconImg{
  position: relative;
  top:0.2vw;
  width: 4vw;
  height: 4vw;
}

#MenuGrid{
  z-index: 6;
  position: absolute;
  top: 34vh;
  width: 16.3vw;
  height: 27vh;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  font-size: 1.6vh;
  text-align: center;
  font-weight: 600;
  
}

#SidebarGrid{
  z-index: 6;
  position: absolute;
  top: 7vh;
  padding-top: 1vh;
  right: 1vw;
  width: 20.3vw;
  height: 43vh;
  box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.3);
  font-size: 1.6vh;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
}

#SidebarGridHeatmap{
  z-index: 6;
  position: absolute;
  top: 7vh;
  padding-top: 1vh;
  right: 1vw;
  width: 20.3vw;
  height: 43vh;
  box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.3);
  font-size: 1.6vh;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
}

.HideSidebarHeatmap{
  top: -20vh;
  position: absolute;
  right: -4.5vw;
  z-index: 100;
}

#SidebarGridSidebar{
  z-index: 5;
  position: absolute;
  top: 6vh;
  padding-top: 1vh;
  right: 0vw;
  width: 25.55vw;
  height: 55.1vh;
  box-shadow: -4px 3px 2px rgba(255, 255, 255, 0.3);
  font-size: 1.6vh;
  text-align: center;
  font-weight: 600;
  border-radius: 30px;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  border-bottom-left-radius: 0%;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
}


.SidebarGridCard{
  font-family: 'Poppins' !important;
  font-style: normal !important;
  line-height: 200%;
  font-size: 0.80vw;
  width: 10vw;
  text-align: center;
  height: 5vh;
  border-radius: 11px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  font-weight: 600;
  color: #efefef;
  border: none;
  background: #A1A1A1;
  margin-right: 1px !important;
  margin: 10px;
  margin-left: 0px;
  z-index: 3;
}

#CalendarSidebarBtn{
  position: fixed;
  top: 3vh;
  right: 11.25vw;
  box-shadow: -10px 0px 4px -5px rgba(255, 255, 255, 0.3);
}

#ContextSidebarBtn{
  position: fixed;
  top: 3vh;
  width: 10vw;
  right: 0.95vw;
}

.SidebarGridCard.Active{
  
  color: #efefef;
  background: #202020;

}

.SidebarGridCard:hover {
  opacity: 0.96;
  background: #878787;
  cursor: pointer;
}

.SidebarGridCard.Active:hover {
  opacity: 0.96;
  background: #202020;
  cursor: pointer;
}

.GridSelection:hover{
  opacity: 0.96;
  cursor: pointer;
  transform: scale(0.96);
}

.GridGraphicToggleTable{

}
.GridGraphicToggleTable{

}
.GridGraphicToggleText{
  text-align: left;
  padding-left: 3vw;
}
#GridGraphicToggle{
  top: 0;
  right: 2vw;

}


#NightGrid{
  bottom: 4vh;
  left: 9vw;
  position: relative;
}

#DayGrid{
  top: 4.25vh;
  left: 3vw;
  position: relative;
}

.ToggleTableRow{
  
  line-height: 6.2vh;
}

.ToggleTableText{
  padding-left: 1vw;
  text-align: left;
  padding-top: 2.1vh;
}


#InfoCard{
  height: 60vh;
  width: 46vw;
  position: absolute;
  left: 25vw;
  top: 20vh;
  z-index: 10000;
  background-color: #f3f3f3e0;
  border-radius: 25px;
  border: solid 3px #FFFFFF;
  color: #202020;
  text-align: center;
  padding-top: 7vh;
  padding-left: 5.5vw;
  padding-right: 2vw;
}

#InfoCardTitle{
  font-size: 4vh;
  font-weight: 200;
}

#InfoCardDate{
  font-weight: 200;
  font-size: 2vh;
  
}

#InfoTable{
  text-align: left;
  font-weight: 200;
  font-size: 2vh;
  border-spacing: 0.7vw;
}

.InfoTableCol{
 text-align: center;
 padding-right: 0.8vw;
}

.IconTable{
  margin: 0px;
  margin-right: 0.71vw;
}

#InfoCardClose{
  cursor: pointer;
  color: #BD1818;
  font-weight: 1000;
  font-size: 3vh;
  right: -1vw;
  position: relative;
  text-align: right;
  top: -6vh;
}

.IconTable:hover{
  color: #efefef !important;
  background: #85bc19 !important;
  opacity: 1 !important;
  cursor: auto !important;
  transform: none !important;
}

.imgInfo:hover{
  opacity: 1 !important;
  cursor: auto !important;
  transform: none !important;
}

#popUpCall{
  width: 2vw;
  height: 2vw;
  background-color: #2020207e;
  position: absolute;
  left: 18.3vw;
  bottom: 4vh;
  z-index: 10000;
  box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-color: rgba(255, 255, 255, 0.3);
  text-align: center;
  color: #EFEFEF;
  padding: 0.5vw;
  font-size: 1.8vw;
}

#popUpCall:hover{
  cursor: pointer;
  background-color: #8787877e;
}






@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.esri-view .esri-view-surface--inset-outline:focus::after {
  outline: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #878787;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #85bc19;
  border-radius: 10px;
}
/*1vh = 9.07px
  1vw = 16.8px
*/
.switch {
  position: relative;
  display: inline-block;
  width: 3.57vw;
  height: 3.74vh;
  right: -2.5vw;
  top: 1.5vh;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #85bc19;
  -webkit-transition: .4s;
  transition: .4s;
}

.time-picker {
  position: relative;
  display: inline-block;
  z-index: 1000000;
  position: absolute;
  top: 77.5vh;
  left: 6.2vw;
}
.time-picker-button {
  border: 1px solid #efefef;
  border-radius: 4px;
  background: #202020;
  color: #efefef;
  cursor: pointer;
}

.time-picker-button:hover {
  background: #efefef17
}


.time-picker-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: #202020;
  

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100px;
  z-index: 10;
}
.time-picker-list {
  max-height: 150px;
  overflow-y: auto;

}
.time-picker-item {
  display: block;
  color: #efefef;
  width: 100%;
  padding: 5px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}
.time-picker-item:hover, .time-picker-item.selected {
  background: #85bc19;
  color: white;
}


.slider:before {
  position: absolute;
  content: "";
  height: 2.86vh;
  width: 1.54vw;
  right: 0.23vw;
  bottom: 0.44vh;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

#searchBtn{
  top: 15px;
  right: 26.7vw;
  width: 150px !important;
  position: absolute;
  z-index: 20000;
}

.searchBtnXp{
  right: 13.7vw !important; 
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(-1.547vw);
  -ms-transform: translateX(-1.547vw);
  transform: translateX(-1.547vw);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}



.slider.round:before {
  border-radius: 50%;
}

.esri-search {
  background: #2020202c;
  color: #efefef;
  
}

.esri-search__clear-button{
  background: #2020202c;
  color: #efefef;
  
}

.esri-search__input{
  background: #2020202c;
  color: #efefef;
  
}

.esri-search__container{
  border: solid #878787;
  border-radius: 8px;
}

.esri-search__submit-button{
  background: #2020202c;
  color: #efefef;
}

.esri-menu__list-item{
  color: #202020;
}


* {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    line-height: 120%;
  }

